.ReactTable {
  border: none;
  overflow: hidden;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  margin-top: 28px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.ReactTable .rt-noData > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ReactTable .rt-thead {
  position: relative;
}
.ReactTable .rt-table {
  border-radius: 0 0 6px 6px;
  background: #fff;
}
.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-thead .rt-th {
  border-right: none;
  padding: 3px 5px;
}

.ReactTable .rt-thead .rt-th {
  line-height: 30px;
}

.ReactTable .rt-thead .rt-thead__progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.ReactTable .-loading {
  top: 37px;
  bottom: 0px;
}

.ReactTable .-loading.-active {
  z-index: 8;
}

.ReactTable .rt-thead .rt-tr,
.ReactTable .rt-tbody .rt-tr {
  text-align: left;
  align-items: center;
}

.ReactTable .rt-tbody .rt-tr.-no-text-selection {
  user-select: none;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
}
.rt-thead {
  box-shadow: none;
  border-bottom: 1px solid rgb(217, 217, 217, 0.3);
  font-size: 13px;
  color: #88909d;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid rgb(217, 217, 217, 0.3);
}
.rt-tbody {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: #424b68;
  padding-bottom: 20px;
}
.ReactTable .-pagination {
  box-shadow: none;
  border-top: none;
}
.rt-table {
  margin-bottom: 20px;
}

.ReactTable .-pagination .-btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  background: #424b68;
  border-radius: 17px;
  width: inherit;
  height: 34px;
  padding: 0 10px;
}
.-pagination .-btn[disabled] {
  color: #88909d;
  border: none;
  background: none;
}

.ReactTable .rt-td:first-child,
.ReactTable .rt-th:first-child {
  padding-left: 16px;
}
.ReactTable .rt-td:last-child,
.ReactTable .rt-th:last-child {
  padding-right: 16px;
}
.pagination-bottom {
  display: grid;
  place-items: center;
  color: #88909d;
}

/* RTL adjust */
html[dir="rtl"] .ReactTable .rt-thead .rt-tr,
html[dir="rtl"] .ReactTable .rt-tbody .rt-tr {
  text-align: right;
  align-items: center;
}

html[dir="rtl"] .ReactTable .rt-td:first-child,
html[dir="rtl"] .ReactTable .rt-th:first-child {
  padding-left: 0;
  padding-right: 16px;
}
html[dir="rtl"] .ReactTable .rt-td:last-child,
html[dir="rtl"] .ReactTable .rt-th:last-child {
  padding-right: 0;
  padding-left: 16px;
}